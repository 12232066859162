import './about.scss';

import { Link } from 'gatsby';
import React from 'react';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import SubHeader from '../../components/SubHeader';

import PigbankIcon from '../../icons/pigbank-icon.svg';
import MoneyIcon from '../../icons/money-icon.svg';
import PaperPenIcon from '../../icons/paper-pen-icon.svg';
import HeartHandIcon from '../../icons/heart-hand-icon.svg';
import CobraIcon from '../../icons/cobra-icon.svg';
import HandDollarIcon from '../../icons/hand-dollar-icon.svg';
import ReimbursementIcon from '../../icons/reimbursement-icon.svg';

const innerPages = [
  {
    to: '/services/enrollment-benefit-admin',
    title: 'Online Enrollment & Benefit Admin Services',
    icon: PaperPenIcon
  },
  {
    to: '/services/flexible-spending-accounts',
    title: 'Flexible Spending Accounts (FSA)',
    icon: MoneyIcon
  },
  {
    to: '/services/health-savings-accounts',
    title: 'Health Savings Accounts (HSA)',
    icon: PigbankIcon
  },
  {
    to: '/services/health-reimbursement-arrangements',
    title: 'Health Reimbursement Arrangements (HRA)',
    icon: HandDollarIcon
  },
  {
    to: '/services/qsehra',
    title: 'Qualified Small Employer Health Reimbursement Arrangements (QSEHRA)',
    icon: ReimbursementIcon
  },
  {
    to: '/services/cobra-administration',
    title: 'COBRA Administration',
    icon: CobraIcon
  },
  {
    to: '/services/retiree-billing',
    title: 'Retiree Billing',
    icon: HeartHandIcon
  }
];

const AboutPage = () => (
  <Layout>
    <SEO title="About" />


      <SubHeader title="About Tri-Star Systems" />

      <section className="inner-page">
        <div className="inner-page__sidebar">
          <h3 className="inner-page__nav-header">All Services</h3>
          <nav className="inner-page__nav">
          {innerPages.map((page,
            i) => (
            <Link
              key={`${page.to}-${i}`}
              to={page.to}
              className="inner-page__nav-link"
              activeClassName="inner-page__nav-link--active"
            >
              <page.icon />
              {page.title}
            </Link>
          ))}
          </nav>
        </div>
        <div className="inner-page__main">
          <section className="about-section about-section--smaller">
            <h3>Brief History</h3>
            <p className="section-heading__lead">Tri-Star Systems began in 1987 when two men, Ed Crawford and Steve Hargrave, joined forces to provide a solution for <a href="/services/enrollment-benefit-admin">employee benefits enrollment, carrier eligibility coordination</a>, and <a href="/services/flexible-spending-accounts">Section 125 plans</a>. The idea was to help customers with solutions for their employee benefits needs that could not be solved internally, nor through generic, bundled, product offerings that had been pitched to them by big box companies. We believe the same is true today, which is why we're still here, still privately owned, and still family run. We're able to deliver these solutions primarily because of two advantages: we have the right people and the right technology.</p>
            <h3>The Right People</h3>
            <p className="section-heading__lead">What makes someone the Right Person for Tri-Star? Tri-Star Systems' requirements are that you have to know your stuff, and you have to have a desire to help people. Our management team has been around a long time, long enough to strike the right balance of knowing what works and what doesn't. Individually and collectively, we have years of experience in Employee Benefits, Customer Service, and Technology. The members of our Customer Service teams are personable and knowledgable. They are not an anonymous, outsourced, first level call center, reading through some online knowledge base. They are Tri-Star employees trained to answer the phones promptly and courteously, with the intent and patience to answer your questions.</p>
            <h3>The Right Technology</h3>
            <p className="section-heading__lead">One of the reasons we were able to provide a customized solution back when we began is because we had our own proprietary software, which meant we could make any change we wanted. We've made several enhancements over the years, including adding the ability to offer solutions for <a href="/services/cobra-administration">COBRA administration</a>, <a href="/services/retiree-billing">Retiree billing</a>, <a href="/services/health-reimbursement-arrangements">HRA administration</a>, and <a href="/services/health-savings-accounts">HSAs</a>. Today, we're able to handle a growing customer base with multiple benefit plans in a single integrated system. Owning our own technology gives us the flexibility to respond quickly to your organization’s customization needs and ad hoc reporting requirements. We have been able to implement customers in days what has taken others to do in months, if at all.</p>
            <p className="section-heading__lead">How can Tri-Star help administer your employee benefits? Call us at <a href="tel:3145764022">314.576.4022</a> or email us at <a href="mailto:info@tri-starsystems.com">info@tri-starsystems.com</a>.</p>
          </section>
        </div>
      </section>

  </Layout>
);

export default AboutPage
